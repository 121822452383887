/* iPhoneFrame.css */
.iphone-container {
    perspective: 500px; /* Adjusted for smaller size */
    perspective-origin: 100px 50%; /* Adjusted for smaller size */
}

.iphone-frame {
    width: 153px; /* Smaller frame width */
    height: 280px; /* Smaller frame height */
    background-color: #040101;
    border-radius: 25px; /* Adjusted for smaller size */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adjusted shadow for smaller size */
    transform-style: preserve-3d;
    transform: rotateY(-10deg);
    position: relative;
}

.iphone-screen {
    width: 147px; /* Smaller screen width */
    height: 273px; /* Smaller screen height */
    background-color: white;
    position: absolute;
    top: 3.5px; /* Adjusted for smaller size */
    left: 3px; /* Adjusted for smaller size */
    border-radius: 20px; /* Adjusted for smaller size */
    z-index: 1;
}



.iphone-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 18px; /* Adjusted to match the iphone-screen border-radius */
}
.iphone-top-notch {
    width: 60px; /* Significantly smaller notch width */
    height: 10px; /* Smaller notch height */
    background-color: #000;
    position: absolute;
    top: 5px; /* Position adjusted to fit within the frame */
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px; /* Smooth out the corners a bit more for a smaller notch */
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iphone-top-notch:before,
.iphone-top-notch:after {
    content: '';
    position: absolute;
    top: 2px; /* Position adjusted for smaller elements within the notch */
}

.iphone-top-notch:before {
    /* Represents the speaker */
    width: 30px; /* Width of the speaker */
    height: 4px; /* Height of the speaker */
    background-color: #666; /* Speaker color */
    border-radius: 2px;
    left: 5px; /* Position from the left inside the notch */
}

.iphone-top-notch:after {
    /* Represents the camera */
    width: 6px; /* Diameter of the camera */
    height: 6px; /* Diameter of the camera */
    background-color: #666; /* Camera color */
    border-radius: 50%; /* Makes it circular */
    right: 5px; /* Position from the right inside the notch */
}