
@tailwind base;
@tailwind components;
@tailwind utilities;



body {
    background-color: #F0F0F0;
    margin: 0; /* Reset margin to remove any default spacing */
    padding: 0; /* Reset padding to remove any default spacing */
  }
  