.whatsapp-icon {
    position: fixed;
    right: 1px;
    bottom: 50px;
    background-color: #25D366; /* WhatsApp brand color */
    border-radius: 0%;
    display: flex;
    flex-direction: column; /* Aligns children (icon and text) in a column */
    justify-content: center;
    align-items: center;
    padding: 8px; /* Adjust padding as necessary */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    text-decoration: none;
  }
  
  .whatsapp-icon svg {
    color: white;
    font-size: 24px; /* Adjust icon size as necessary */
    margin-bottom: 4px; /* Space between the icon and text */
  }
  
  .whatsapp-icon span {
    color: white; /* Text color */
    font-size: 14px; /* Adjust text size as necessary */
    margin: 0; /* Remove any default margins */
  }
  