/* Overall container for the page */
.container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  padding: 20px; /* Padding around the container */
  background-color: #f8f8f8; /* Light grey background for contrast */
}

/* Container specifically for centering buttons */
.button-container {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
  gap: 10px; /* Spacing between buttons */
  margin-bottom: 20px; /* Space below the button container */
}

/* Styles for individual buttons */
.btn {
  padding: 8px 16px; /* Smaller padding for a more compact button */
  margin: 0; /* Remove margin for tighter spacing */
  border-radius: 20px; /* Slightly rounded corners for aesthetic */
  background-color: #e0e0e0; /* Light grey background */
  color: #000; /* Black text for readability */
  font-size: 14px; /* Smaller font size for button text */
  border: none; /* No border for a cleaner look */
  cursor: pointer; /* Indicates an interactive element */
  transition: background-color 0.3s, transform 0.1s; /* Smooth transition for hover effects */
}

/* Hover effect for buttons */
.btn:hover {
  background-color: #d5d5d5; /* Slightly darker on hover for visual feedback */
  transform: translateY(-2px); /* Subtle lift effect on hover */
}

/* Active state for a button, if needed */
.btn.active {
  background-color: #FD8D14; /* Standout color for an active button */
  color: #fff; /* Contrast text color */
}

/* Responsive styles, if needed */
@media (max-width: 768px) {
  .container {
    padding: 10px; /* Less padding on smaller screens */
  }

  .btn {
    padding: 8px 12px; /* Even smaller padding on smaller screens */
  }

  /* Adjust button container for smaller screens, if necessary */
  .button-container {
    flex-direction: column; /* Stack buttons vertically on small screens */
  }
}
