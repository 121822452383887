/* Main container for the entire page, if not already set */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    /* Ensure full height of the page */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  /* Footer container */
  .footer-container {
    background-color: #090909d3; /* Dark background for a modern look */
    color: white;
    padding: 40px 0;
    width: 100%; /* Full width */
    box-sizing: border-box; /* Ensures padding doesn't add to the width */
    margin-top: auto; /* Pushes the footer to the bottom */
  }
  
  .footer-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    margin: 0 auto; /* Center content */
    max-width: 1200px; /* Or any max-width you prefer */
    padding: 0 15px; /* Padding for small screens */
  }
  
  .footer-section {
    display: flex;
    flex-direction: column;
  }
  
  .footer-title {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .footer-text, .footer-links, .social-icons {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li a {
    color: #bbb;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-links li a:hover {
    color: #fff;
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
  }
  
  .social-icon {
    color: #bbb;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #fff;
  }
  
  .footer-bottom {
    text-align: center;
    font-size: 0.8rem;
    margin-top: 20px; /* Spacing above the bottom text */
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .footer-content {
      grid-template-columns: 1fr;
    }
  }
  